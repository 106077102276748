import React from "react";

import {
  EuiEmptyPrompt,
  EuiPage,
  EuiPageBody,
  EuiPageContent,
} from "@elastic/eui";

function EmptyPage({ title }) {
  return (
    <EuiPage>
      <EuiPageBody>
        <EuiPageContent>
          <EuiEmptyPrompt
            iconType="editorStrike"
            title={<h2>{title}</h2>}
            body={<p>This feature is not yet available</p>}
          />
        </EuiPageContent>
      </EuiPageBody>
    </EuiPage>
  );
}

export default EmptyPage;
