import { Router, Switch, Route } from "react-router-dom";
import { EuiPage } from "@elastic/eui";

import history from "utils/history";
import SignUp from "modules/sign-up";
import Locations from "modules/locations";
import NotFound from "modules/not-found";

function App() {
  return (
    <EuiPage>
      <Router history={history}>
        <Switch>
          <Route path="/:tenant/register" component={SignUp} />
          <Route path="/:tenant/locations" component={Locations} />          
          <Route component={NotFound} />
        </Switch>
      </Router>
    </EuiPage>
  );
}

export default App;
