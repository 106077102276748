import { useEffect, useState, useCallback } from "react";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";

import { EuiFormRow, EuiFilePicker } from "@elastic/eui";

import { isFile } from "modules/commons/utils/validations";

import "./index.css";

function FileField({
  loading = false,
  state = {},
  options = [],
  onChange = () => { },
} = {}) {
  const [image, setImage] = useState("");

  const onFileChange = useCallback((files) => {
    const file = _get(files, "[0]", {});
    onChange(file);
  }, []);

  useEffect(() => {
    const process = async () => {
      try {
        isFile(state?.value);

        const url = URL.createObjectURL(state?.value)

        setImage(url);
      } catch {
        setImage("");
      }
    };

    process();
  }, [!!state?.value?.name]);

  return (
    <EuiFormRow
      fullWidth
      label={state.title}
      helpText={state?.error?.message || ""}
      className="FileField"
    >
      <div>
        {image && (
          <div className="FileField__Preview">
            <img
              src={image}
              alt={state.title}
              className="FileField__Preview__Img"
            />
          </div>
        )}

        <EuiFilePicker
          fullWidth
          disabled={state.disabled}
          isInvalid={!!state.error}
          isLoading={loading}
          onChange={onFileChange}
          options={options}
        />
      </div>
    </EuiFormRow>
  );
}

export default FileField;
