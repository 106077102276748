import { EuiFlexGroup, EuiFlexItem, EuiText } from "@elastic/eui";
import RawHTML from "./RawHTML";

function RawHTMLGroup({ htmlContent }) {
  return (
    <EuiFlexGroup>
      <EuiFlexItem>
        <EuiText grow={false}>
          <RawHTML htmlContent={htmlContent} />
        </EuiText>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
}

export default RawHTMLGroup;
