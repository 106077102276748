import { createContext } from "react";

import { useFormField } from "modules/commons/hooks";

import utils from "modules/commons/utils";

const FormContext = createContext();

export const useFormValue = ({ config }) => {
  const basicForm = {
    reservation: {
      expirationDate: useFormField({
        title: "Reservation Expiration Date",
        value: null,
        //value: "2022-07-06 14:17:10",
      }),
    },
    appointmentResponse: {
      code: useFormField({
        title: "Code",
        value: null,
        disabled: true,
      }),
      time: useFormField({
        title: "Time",
        value: null,
        disabled: true,
      }),
    },
    appointmentTime: useFormField({
      title: "Appointment Time *",
      value: null,
      validation: utils.validations.nearFutureDate,
    }),
    appointmentReservationId: useFormField({
      title: "Appointment Reservation Id",
      value: null,
    }),
    firstName: useFormField({
      title: "First Name *",
      value: "",
      validation: utils.validations.isNotEmpty,
    }),
    lastName: useFormField({
      title: "Last Name *",
      value: "",
      validation: utils.validations.isNotEmpty,
    }),
    dob: useFormField({
      title: "Date of Birth *",
      placeholder: "MM/DD/YYYY",
      validation: utils.validations.dateOfBirth,
    }),
    gender: useFormField({
      title: "Gender *",
      value: "",
      validation: utils.validations.isNotEmpty,
    }),
    phone: useFormField({
      title: "Phone *",
      value: "",
      validation: utils.validations.isPhone,
    }),
    email: useFormField({
      title: "Email *",
      value: "",
      validation: utils.validations.isEmail,
    }),

    street: useFormField({
      title: "Address *",
      value: "",
      validation: utils.validations.isNotEmpty,
    }),
    apt: useFormField({
      title: "Apt",
      value: "",
    }),
    city: useFormField({
      title: "City *",
      value: "",
      validation: utils.validations.isNotEmpty,
    }),
    state: useFormField({
      title: "State *",
      value: "",
      validation: utils.validations.isNotEmpty,
    }),
    zip: useFormField({
      title: "Zip Code *",
      value: "",
      validation: utils.validations.isNotEmpty,
    }),

    idCard: useFormField({
      title: "I don't have a government issued ID? *",
      value: false,
      // validation: utils.validations.isNotEmpty,
    }),
    idNumber: useFormField({
      title: "Card Number# *",
      value: "",
      validation: utils.validations.isNotEmpty,
    }),
    idState: useFormField({
      title: "State* ",
      value: "",
      validation: utils.validations.isNotEmpty,
    }),
    idPictureFileName: useFormField({
      title: "Picture* ",
      value: "",
      validation: utils.validations.isFile,
    }),
    paymentType: useFormField({
      title: "Payment Type",
      value: "insurance",
    }),
    insurance: useFormField({
      title: "I don't have health insurance? *",
      value: false,
    }),
    insuranceMemberName: useFormField({
      title: "Insurance Member Name *",
      value: "",
      validation: utils.validations.isNotEmpty,
    }),
    insuranceProvider: useFormField({
      title: "Insurance Provider Name *",
      value: "",
      validation: utils.validations.isNotEmpty,
    }),
    insuranceGroupNumber: useFormField({
      title: "Insurance Group Number *",
      value: "",
    }),
    insuranceMemberNumber: useFormField({
      title: "Insurance Member Number *",
      value: "",
      validation: utils.validations.isNotEmpty,
    }),
    insuranceCardPictureFileName: useFormField({
      title: "Picture *",
      value: "",
      validation: utils.validations.isFile,
    }),

    creditCardNumber: useFormField({
      title: "Credit Card Number *",
      name: "number",
      type: "tel",
      pattern: "[\\d| ]{16,22}",
      autocomplete: "cc-number",
      validation: utils.validations.isCreditCard,
      formatter: utils.payments.formatCreditCardNumber,
    }),
    creditCardExpiration: useFormField({
      title: "Expiration (MM/YY) *",
      value: "",
      name: "expiry",
      type: "tel",
      pattern: "\\d\\d/\\d\\d",
      autocomplete: "cc-exp",
      validation: utils.validations.isNotEmpty,
      formatter: utils.payments.formatExpirationDate,
    }),
    creditCardCvv: useFormField({
      title: "CVV *",
      name: "cvc",
      type: "tel",
      pattern: "\\d{3,4}",
      autocomplete: "cc-csc",
      validation: utils.validations.isNotEmpty,
      formatter: utils.payments.formatCVC,
    }),
    creditCardName: useFormField({
      title: "Name on Card *",
      value: "",
      name: "name",
      type: "text",
      autocomplete: "cc-name",
      validation: utils.validations.isNotEmpty,
    }),
    creditCardZipCode: useFormField({
      title: "Zip Code *",
      value: "",
      autocomplete: "postal-code",
      validation: utils.validations.isZipCode,
    }),
    consent: useFormField({
      value: false,
      title:
        "I understand the above statements. I had the opportunity to ask questions and have had them answered.",
      validation: utils.validations.isTrue,
    }),
  };

  const medicalForm = {
    testReason: useFormField({
      title:
        "Please select ONE of the following that best describes the reason you are getting a test:*",
      value: "",
      validation: utils.validations.isNotEmpty,
    }),

    symptom: useFormField({
      value: false,
      title:
        "Do you have any of the following symptoms: (Cough, Shortness of Breath, Muscle Aches, Diarrhea, Fever (>100.4 F or 38 C), Sore Throat, Chills, Nausea/Vomiting, Runny Nose, Headache, Abdominal Pain)?",
    }),
    symptomCough: useFormField({
      title: "Cough",
      value: false,
    }),
    symptomBreath: useFormField({
      title: "Shortness of Breath",
      value: false,
    }),
    symptomMuscle: useFormField({
      title: "Muscle Aches",
      value: false,
    }),
    symptomDiarrhea: useFormField({
      title: "Diarrhea",
      value: false,
    }),
    symptomFever: useFormField({
      title: "Fever (>100.4° F or 38° C)",
      value: false,
    }),
    symptomThroat: useFormField({
      title: "Sore Throat",
      value: false,
    }),
    symptomChills: useFormField({
      title: "Chills",
      value: false,
    }),
    symptomVomiting: useFormField({
      title: "Nausea/Vomiting",
      value: false,
    }),
    symptomNose: useFormField({
      title: "Runny Nose",
      value: false,
    }),
    symptomHeadache: useFormField({
      title: "Headache",
      value: false,
    }),
    symptomAbdominal: useFormField({
      title: "Abdominal Pain",
      value: false,
    }),
    symptomDate: useFormField({
      title: "When did the symptoms start? *",
      placeholder: "MM/DD/YYYY",
      value: "",
      validation: utils.validations.dateWithinYear,
    }),

    medicalQuestion1: useFormField({
      value: false,
      title:
        "In the past 2 weeks (14 days) have you or anyone in your household had contact with any person confirmed to have contracted coronavirus (COVID-19)? *",
    }),
    medicalQuestion2: useFormField({
      value: false,
      title:
        "Have you been in personal contact with a person infected with Coronavirus or who has traveled to an area with widespread and ongoing transmission of Coronavirus in the past 30 days?",
    }),
    medicalQuestion3: useFormField({
      value: false,
      title:
        "In the past 2 weeks (14 days) have you or anyone in your household had contact with any person suspected to have contracted coronavirus (COVID-19)? Including being tested for COVID-19, & being in self isolation for COVID-19 *",
    }),
    medicalQuestion4: useFormField({
      value: false,
      title:
        "Has the individual currently been exposed to someone with flu-like symptoms (cough, shortness of breath or fever) *",
    }),
    medicalQuestion5: useFormField({
      value: false,
      title:
        "Have you traveled to a U.S. City/State with reported cases of Coronavirus in the past 30 days? *",
    }),
    medicalQuestion6: useFormField({
      value: false,
      title: "Have you traveled outside the U.S. in the past 30 days? *",
    }),
  };

  let finalForm = basicForm;

  if (config.includeMedicalForm) {
    finalForm = Object.assign({}, basicForm, medicalForm);
  }

  return finalForm;
};

export function FormProvider({ children, config }) {
  return (
    <FormContext.Provider
      value={useFormValue({
        config: config,
      })}
      children={children}
    />
  );
}

export default FormContext;
