import { useCallback } from "react";

import _get from "lodash/get";
import axios from "utils/axios";
import compress from "modules/commons/utils/compress";

const isFile = (file: File) => file instanceof File;

const useUploadFile = () => {
  const upload = useCallback(async (file: File) => {
    if (!isFile(file)) return "";

    const url = await compress(file);
    const data = toBase64(url);

    const response = await axios.post("/PatientRegistration/uploaddata", {
      data,
    });

    return _get(response, "data.fileName", "");
  }, []);

  return { upload };
};

const toBase64 = (image: string) => {
  return image.replace("data:", "").replace(/^.+,/, "");
};

export default useUploadFile;