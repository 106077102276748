import Covid from "./Covid";
import MonkeypoxVaccine from "./MonkeypoxVaccine";
import Empty from "./Empty";
import Raw from "./Raw";

export default {
  Covid,
  Raw,
  MonkeypoxVaccine,
  Empty,
};
