import moment from "moment";

import { FormField } from "modules/commons/hooks/useFormField";

const medicalFields = [
  "medicalQuestion1",
  "medicalQuestion2",
  "medicalQuestion3",
  "medicalQuestion4",
  "medicalQuestion5",
  "medicalQuestion6",

  "testReason",
  "symptom",
  "symptomAbdominal",
  "symptomBreath",
  "symptomChills",
  "symptomCough",
  "symptomDate",
  "symptomDiarrhea",
  "symptomFever",
  "symptomHeadache",
  "symptomMuscle",
  "symptomNose",
  "symptomThroat",
  "symptomVomiting",
];

const dateFormat = "YYYY-MM-DD";

const group = <T>(
  form: Record<string, FormField> = {},
  fields: string[] = [],
  callback: (key: string, field: FormField) => T
) => {
  return fields
    .filter((field) => form[field] != undefined)
    .map((field) => callback(field, form[field]));
};

const fieldParser = (form: Record<string, FormField>) => {
  const result: Record<string, any> = {};

  for (const [key, field] of Object.entries(form)) {
    if (medicalFields.includes(key)) continue;

    const value = field?.state?.value;

    switch (key) {
      case "dob":
        result[key] = moment(value).format(dateFormat);
        break;

      case "appointmentTime":
        result[key] = value == null ? null : `${value}Z`;
        break;

      case "reservation":
      case "appointmentResponse":
      case "reservationExpirationDate":
      case "idPictureFileName":
      case "insuranceCardPictureFileName":
      case "creditCardNumber":
      case "creditCardCvv":
        result[key] = "";
        break;

      default:
        result[key] = value;
        break;
    }
  }

  result["testingDate"] = moment().format(dateFormat);

  result["medicalInformation"] = group(form, medicalFields, (key, field) => {
    let value = field?.state?.value;

    if (key === "symptomDate" && value) {
      value = moment(value).format(dateFormat);
    }

    return {
      key,
      question: field?.state?.title,
      answer: value,
    };
  });

  return result;
};

export default fieldParser;
