import { createContext } from "react";
import useStepValue from "../hooks/useStepValue";

const StepContext = createContext();

export function StepProvider({ steps, children }) {
  const value = useStepValue(steps);

  return <StepContext.Provider value={value} children={children} />;
}

export default StepContext;
