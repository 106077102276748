const options = [
  {
    value: "",
    text: "Select Option",
  },
  {
    value: "1",
    text: "Yes",
  },
  {
    value: "0",
    text: "No",
  },
];

export default options;
