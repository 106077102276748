const options = [
  {
    value: "",
    text: "Select test type",
  },
  {
    value: "1",
    text: "PCR",
  },
  {
    value: "2 ",
    text: "Rapid ",
  },
];

export default options;
