import { useMemo } from "react";
import { useLocation } from "react-router-dom";

function useQuery() {
  const search = useLocation().search;

  const query = useMemo(() => {
    return new URLSearchParams(search);
  }, [search]);

  return query;
}

export default useQuery;
