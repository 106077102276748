import moment from "moment";
import validator from "validator";

export function isTrue(value) {
  if (value == true) {
    return;
  }

  throw new Error("Invalid field");
}

export function isNotEmpty(value) {
  if (!validator.isEmpty(value)) {
    return;
  }

  throw new Error("Empty field");
}

export function dateOfBirth(value) {
  if (!value) throw new Error("No date selected");

  const to = Date.now();
  const from = moment(to).subtract(120, "years");
  const isBetween = moment(value).isBetween(from, to);
  if (isBetween) {
    return;
  }

  throw new Error("Invalid date");
}

export function dateWithinYear(value) {
  if (!value) throw new Error("No date selected");

  const to = Date.now();
  const from = moment(to).subtract(1, "years");
  const isBetween = moment(value).isBetween(from, to);
  if (isBetween) {
    return;
  }

  throw new Error("Invalid date");
}

export function nearFutureDate(value) {
  if (!value) throw new Error("No date selected");

  const from = Date.now();
  const to = moment(from).add(2, "months");
  const isBetween = moment(value).isBetween(from, to);
  if (isBetween) {
    return;
  }

  throw new Error("Invalid date");
}

export function isEmail(value) {
  if (validator.isEmail(value)) {
    return;
  }

  throw new Error("Invalid email address");
}

export function isCreditCard(value) {
  if (validator.isCreditCard(value, "US")) {
    return;
  }

  throw new Error("Invalid Credit Card");
}

export function isZipCode(value) {
  if (validator.isPostalCode(value, "US")) {
    return;
  }

  throw new Error("Invalid Zip Code");
}

export function isPhone(value = "") {
  if (validator.isMobilePhone(value)) {
    return;
  }

  throw new Error("Invalid phone number");
}

export function isFile(file) {
  const extRegex = /(\.jpg|\.jpeg|\.png)$/i;
  if (file instanceof File && extRegex.exec(file.name)) {
    return;
  }

  throw new Error("Invalid file");
}
