import { useContext } from "react";

import { EuiFlexGroup, EuiFlexItem, EuiTitle, EuiText } from "@elastic/eui";

import { CheckboxField } from "modules/commons/components";

import FormContext from "modules/sign-up/context/FormContext";
import Validation from "modules/sign-up/components/Validation";

function Medical({ companyName = '' }) {
  const form = useContext(FormContext);

  return (
    <Validation fields={["consent"]}>
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiText grow={false}>
            <h1>Informed Consent for COVID-19 Testing</h1>

            <p>Please carefully read the following informed consent:</p>

            <ol>
              <li>
                I authorize this COVID-19 testing unit to conduct collection and
                testing for COVID-19 through a nasopharyngeal swab,
                oropharyngeal swab, and/or saliva sample as ordered by an
                authorized medical provider or public health official.
              </li>
              <li>
                I authorize my test results to be disclosed to the county,
                state, or to any other governmental entity as may be required by
                law.
              </li>
              <li>
                I acknowledge that a positive test result is an indication that
                I must continue to self-isolate in an effort to avoid infecting
                others.
              </li>
              <li>
                I understand that, as with any medical test, there is the
                potential for false positive or false negative test results.
              </li>
              <li>
                I, the undersigned, have been informed about the test purpose,
                procedures, possible benefits and risks. I have been given the
                opportunity to ask questions before I sign, and I have been told
                that I can ask other questions at any time. I voluntarily agree
                to testing for COVID-19.
              </li>
            </ol>

            <h3>Informed Consent for Telemedicine</h3>

            <ol>
              <li>
                By using the Emergent Testing Lab telehealth portal, I agree to
                receive telehealth services. Telehealth involves the delivery of
                health care services, including assessment, treatment,
                diagnosis, and education, using interactive video, and data
                communications. During my visit, my Emergent Testing health
                provider and I will be able to see and speak with each other
                from remote locations.
              </li>
              <li>
                <p>I understand and agree that:</p>
                <ul>
                  <li>
                    I will not be in the same location or room as my medical
                    provider.
                  </li>
                  <li>
                    Potential benefits of telehealth (which are not guaranteed
                    or assured) include: (i) access to medical care if I am
                    unable to travel to my provider’s office; (ii) more
                    efficient medical evaluation and management; and (iii)
                    during the COVID-19 pandemic, reduced exposure to patients,
                    medical staff and other individuals at a physical location.
                  </li>
                  <li>
                    Potential risks of telehealth include: (1) limited or no
                    availability of diagnostic laboratory, x-ray, EKG and other
                    testing, and some prescriptions, to assist my medical
                    provider in diagnosis and treatment; (ii) my provider’s
                    inability to conduct a hands-on physical examination of me
                    and my condition; and (iii) delays in evaluation and
                    treatment due to technical difficulties or interruptions,
                    distortion of diagnostic images or specimens resulting from
                    electronic transmission issues, unauthorized access to my
                    information , or loss of information due to technical
                    failures. I will not hold Emergent Testing Lab, LLC
                    responsible for lost information due to technical failures.
                  </li>
                </ul>
              </li>
            </ol>

            <h3>
              Informed Consent for Email Communication of Health Information
            </h3>

            <ol>
              <li>
                I, understand that I may request that we communicate with you
                via unencrypted electronic mail (email). Your health is
                important to us and we will make every effort to reasonably
                comply with your request to receive communications via email.
                However, we reserve the right to deny any request for email
                communications when it is determined that granting such a
                request would not be in your interest.
              </li>
              <li>
                <p>
                  Risk of using email to send protected health information,
                  includes, but are not limited to:
                </p>
                <ul>
                  <li>Risk of Unauthorized access by a 3rd party.</li>
                  <li>Unique difficulty in verifying the sender.</li>
                </ul>
              </li>
            </ol>

            <h3>
              Patient Consent to COVID- 19 Testing, Telemedicine and Unencrypted
              Email Communication
            </h3>

            <p>
              By checking the box below, you acknowledge your recognition and
              understanding of the risks of COVID-19 testing, participation in
              telemedicine, and receiving unencrypted email communication of
              your health information, and are hereby consenting to all of these
              despite the stated risks. All clinically relevant messages may be
              incorporated into your medical record.
            </p>

            <p>
              I authorize Emergent Testing Lab, LLC and its associates,
              including but not limited to C-19 Testing LLC, Medrite Medical
              Care PC to request, use or disclose my protected health
              information with other covered entities involved in my care,
              including laboratories, and for reporting purposes, as well as
              release the aforementioned entities from any liability that may
              occur related to covid-19 testing/resulting.
            </p>

            <p>
              By checking the box below, you also acknowledge that you have the
              choice to receive communications via other secure means such as by
              telephone. You agree to hold Emergent Testing Lab and its
              directors, officers, employees, agents, including but not limited
              to C-19 Testing LLC harmless for unauthorized use, disclosure, or
              access of your protected health information sent to the email
              address you provide.
            </p>
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiFlexGroup>
        <EuiFlexItem>
          <CheckboxField {...form.consent} />
        </EuiFlexItem>
      </EuiFlexGroup>
    </Validation>
  );
}

export default Medical;
