import { EuiCallOut } from "@elastic/eui";
import FormContext from "modules/sign-up/context/FormContext";
import { QRCodeSVG } from "qrcode.react";
import { useContext } from "react";

const Complete = ({ doNotShowRequirments }) => {
  const context = useContext(FormContext);

  return (
    <EuiCallOut
      title="Good news, successful registration"
      color="success"
      iconType="user">
      {doNotShowRequirments || (
        <>
          <h3>You will need:</h3>

          <ul>
            <li>Driver's License</li>
            <li>Insurance Card</li>
          </ul>

          <b>
            You do not need your insurance card if you do not have or plan on
            using insurance.
          </b>
        </>
      )}

      {context?.appointmentResponse?.code?.value && (
        <div>
          <div>Registration Code:</div>
          <QRCodeSVG value={context.appointmentResponse.code.value} />
        </div>
      )}
    </EuiCallOut>
  );
};

export default Complete;
