import React, { useContext } from "react";

import { EuiFlexGroup, EuiFlexItem, EuiTitle } from "@elastic/eui";

import { TextField, SelectField } from "modules/commons/components";

import options from "modules/commons/options";

import FormContext from "modules/sign-up/context/FormContext";

function Patient() {
  const context = useContext(FormContext);

  return (
    <>
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiTitle size="s">
            <h2>Address</h2>
          </EuiTitle>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiFlexGroup>
        <EuiFlexItem>
          <TextField {...context.street} />
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiFlexGroup>
        <EuiFlexItem>
          <TextField {...context.apt} />
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiFlexGroup>
        <EuiFlexItem>
          <TextField {...context.city} />
        </EuiFlexItem>
        <EuiFlexItem>
          <SelectField {...context.state} options={options.usaStates} />
        </EuiFlexItem>
        <EuiFlexItem>
          <TextField {...context.zip} />
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  );
}

export default Patient;
