import _get from "lodash/get";

import { FormField } from "modules/commons/hooks/useFormField";
import { useCallback } from "react";

type Form = Record<string, FormField>;

const useValidateFields = (
  fields: string[],
  isFieldOptional?: (field: string, form: Form) => boolean
) => {
  const validate = useCallback((form: Form) => {
    const errors: Record<string, string> = {};

    for (const key of fields) {
      const field = form[key];

      const isOptional = isFieldOptional?.(key, form) || false;

      if (isOptional) continue;

      const error = field.validate?.();

      if (error) {
        errors[key] = _get(error, "message", "");
      }
    }

    return errors;
  }, []);

  return validate;
};

export default useValidateFields;
