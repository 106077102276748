import { useState, useEffect } from "react";
import moment from "moment";
import {
  EuiDatePicker,
  EuiFlexItem,
  EuiFormRow,
  EuiFlexGroup,
  EuiTitle,
} from "@elastic/eui";
import { useFetch } from "modules/commons/hooks";

const ScheduleTimePicker = ({ onChange, locationId, clearValue }) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [timeSlots, setTimeSlots] = useState([]);

  const appointments = useFetch({
    url: `/AppointmentScheduler/location/${locationId}/days`,
  });

  const appointmentsTimes = useFetch({
    url: `/AppointmentScheduler/location/${locationId}/days/slots?date=${selectedDate?.format(
      "YYYY-MM-DD"
    )}`,
  });

  useEffect(() => {
    setSelectedTime(null);
    appointments.fetch();
  }, [clearValue]);

  useEffect(() => {
    const fetchSlots = async () => {
      await appointmentsTimes.fetch();
    };

    if (selectedDate?.isValid()) fetchSlots();
  }, [selectedDate]);

  useEffect(() => {
    if (appointments?.payload?.slots?.length > 0)
      setSelectedDate(moment(appointments?.payload?.slots[0].date));
    else setSelectedDate(null);
  }, [appointments?.payload?.slots]);

  useEffect(() => {
    setTimeSlots(appointmentsTimes?.payload?.slots ?? []);
  }, [appointmentsTimes?.payload?.slots]);

  const handleChangeSelectedDate = (date) => {
    setSelectedDate(date);
    setSelectedTime(null);
    onChange(null);
  };

  const handleSelectedTime = (time) => {
    setSelectedTime(time);

    const formatedTime = `${selectedDate.format("YYYY-MM-DD")}T${time.format(
      "HH:mm:ss"
    )}`;

    onChange(formatedTime);
  };

  if (selectedDate == null || appointments?.payload?.slots == null) {
    return (
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiTitle size="s">
            <h2>
              There are currently no appointments available. Please check back a
              little later.
            </h2>
          </EuiTitle>
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  }

  return (
    <>
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiTitle size="s">
            <h2>Pick an appointment Date</h2>
          </EuiTitle>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiFlexGroup>
        <EuiFlexItem grow={false}>
          <EuiFormRow>
            <EuiDatePicker
              inline
              disabled={appointments.payload?.slots?.length == 0}
              includeDates={appointments.payload?.slots?.map((t) =>
                moment(t.date)
              )}
              shadow={false}
              selected={selectedDate ?? undefined}
              onChange={handleChangeSelectedDate}
            />
          </EuiFormRow>
        </EuiFlexItem>
        {timeSlots.length > 0 && (
          <EuiFlexItem grow={false}>
            <EuiFormRow>
              <EuiDatePicker
                inline
                timeIntervals={60}
                includeTimes={timeSlots.map((t) => moment(t.start))}
                showTimeSelectOnly
                selected={selectedTime}
                onChange={handleSelectedTime}
              />
            </EuiFormRow>
          </EuiFlexItem>
        )}
      </EuiFlexGroup>
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiTitle size="s">
            <>
              {selectedTime && (
                <h2>
                  Selected appointment date:{" "}
                  <b>
                    {moment(
                      `${selectedDate.format(
                        "YYYY-MM-DD"
                      )}T${selectedTime.format("HH:mm:ss")}`
                    ).format("LLL")}
                  </b>
                </h2>
              )}
              {selectedTime == null && (
                <h2>Please selected an appointment date.</h2>
              )}
            </>
          </EuiTitle>
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  );
};

export default ScheduleTimePicker;
