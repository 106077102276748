import { Step } from "./Step";

export const covidSteps = {
  steps: [
    new Step("general"),
    new Step("credentials"),
    new Step("covid-consent"),
    new Step("complete"),
  ],
};

export const covidbasicSteps = {
  steps: [
    new Step("general"),
    new Step("raw-consent"),
    new Step("complete"),
  ],
};

export const covidinsuranceorcreditcardSteps = {
  steps: [
    new Step("general"),
    new Step("credentials", { allowCreditCard: true }),
    new Step("covid-consent"),
    new Step("complete"),
  ],
};

export const level1Steps = {
  includeMedicalForm: true,
  steps: [
    new Step("general"),
    new Step("credentials"),
    new Step("medical"),
    new Step("covid-consent"),
    new Step("complete"),
  ],
};

export const level1enforceinsuranceSteps = {
  includeMedicalForm: true,
  steps: [
    new Step("general"),
    new Step("credentials", { requireInsurance: true }),
    new Step("medical"),
    new Step("covid-consent"),
    new Step("complete"),
  ],
};

export const level1insuranceorcreditcardSteps = {
  includeMedicalForm: true,
  steps: [
    new Step("general"),
    new Step("credentials", { allowCreditCard: true }),
    new Step("medical"),
    new Step("covid-consent"),
    new Step("complete"),
  ],
};

export const monkeypoxvaccineSteps = {
  steps: [
    new Step("monkeypox-vaccine-intro-info"),
    new Step("schedule"),
    new Step("general", { hide: { gender: true } }),
    //new Step("monkeypox-vaccine-consent"),
    new Step("empty-consent"),
    new Step("appointment-complete"),
  ],
};
