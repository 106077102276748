import { useContext, useEffect, useState } from "react";

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiButton,
} from "@elastic/eui";

import { Show } from "modules/commons/components";

import FormContext from "modules/sign-up/context/FormContext";
import StepContext from "modules/sign-up/context/StepContext";
import useSubmit from "./hooks/useSubmit";
import useValidateFields from "./hooks/useValidateFields";
import { FormField } from "modules/commons/hooks/useFormField";

interface ValidateFieldProps {
  fields?: string[];
  isFieldOptional?: (key: string, form: Record<string, FormField>) => boolean;
  onNext?: () => Promise<boolean> | boolean;
  canContinue?: boolean;
  nextButtonText?: string;
}

const Validation: React.FC<ValidateFieldProps> = ({
  fields = [],
  children,
  isFieldOptional,
  nextButtonText = "Next",
  onNext = () => true,
  canContinue = true,
}) => {
  const [nextButtonClicked, setNextButtonClicked] = useState(false);
  const form = useContext(FormContext);
  const { currentIndex, currentStep, nextStep, backStep } =
    useContext(StepContext);
  const validate = useValidateFields(fields, isFieldOptional);

  const { isLoading, submit } = useSubmit((data) => {
    form.appointmentResponse.code.onChange(data.code);
    form.appointmentResponse.time.onChange(data.code);
    nextStep();
  });

  const onClickNext = async () => {
    if (fields.length < 0) return;

    setNextButtonClicked(true);

    const errors = validate(form);

    console.log("validation", currentStep, errors);

    if (Object.values(errors).length == 0) {
      const canGoNext = await onNext();

      if (canGoNext) {
        if (currentStep.name.endsWith("-consent")) {
          await submit(form);
        } else {
          nextStep();
        }
      }
    }
    
    setNextButtonClicked(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {children}
      <EuiHorizontalRule />
      <EuiFlexGroup>
        <Show when={currentIndex > 0}>
          <EuiFlexItem>
            <EuiButton onClick={backStep} disabled={isLoading}>
              Back
            </EuiButton>
          </EuiFlexItem>
        </Show>

        {currentStep.name.endsWith("-consent") ? (
          <EuiFlexItem>
            <EuiButton
              fill
              color="secondary"
              isDisabled={isLoading}
              isLoading={isLoading}
              onClick={onClickNext}>
              Submit
            </EuiButton>
          </EuiFlexItem>
        ) : (
          <EuiFlexItem>
            <EuiButton
              fill
              onClick={onClickNext}
              isLoading={nextButtonClicked}
              disabled={!canContinue || nextButtonClicked}>
              {nextButtonText}
            </EuiButton>
          </EuiFlexItem>
        )}
      </EuiFlexGroup>
    </>
  );
};

export default Validation;
