import Validation from "modules/sign-up/components/Validation";
import MonkeypoxDisclaimer from "./MonkeypoxDisclaimer";

function MonkeypoxVaccineInfo({ companyName = "" }) {
  return (
    <Validation>
      <MonkeypoxDisclaimer />
    </Validation>
  );
}

export default MonkeypoxVaccineInfo;
