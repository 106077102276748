import { useCallback, useState } from "react";
import _get from "lodash/get";
import axios from "utils/axios";
import useQuery from "./useQuery";

type Status = "loading" | "ready" | "error";

const useLocation = () => {
  const query = useQuery();
  const [locationType, setLocationType] = useState<any>();
  const [status, setStatus] = useState<Status>("loading");

  const fetchLocation = useCallback(async () => {
    const id = query.get("id");

    try {
      const response = await axios.get(
        `https://resultapi.medritesoft.com/info/location/${id}`
      );

      const locationType = _get(response, "data", "");

      setLocationType(locationType);
      setStatus("ready");
    } catch (error) {
      setStatus("error");
    }
  }, []);

  return { fetchLocation, locationType, status };
};

export default useLocation;
