import { useContext } from "react";

import _get from "lodash/get";

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiTitle,
  EuiSpacer,
  EuiHorizontalRule,
} from "@elastic/eui";

import {
  SelectField,
  CheckboxField,
  Show,
  DateField,
} from "modules/commons/components";

import Validation from "modules/sign-up/components/Validation";

import FormContext from "modules/sign-up/context/FormContext";

import Symptoms from "./Symptoms";
import Questions from "./Questions";

function Medical() {
  const context = useContext(FormContext);

  return (
    <Validation
      fields={["testReason", "symptomDate"]}
      isFieldOptional={(key, form) => {
        if (key === "symptomDate") {
          const hasSymptoms = form?.["symptom"]?.state.value;
          return !hasSymptoms;
        }

        return false;
      }}
    >
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiTitle size="s">
            <h2>Test Reason</h2>
          </EuiTitle>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiFlexGroup>
        <EuiFlexItem>
          <SelectField
            {...context.testReason}
            options={[
              {
                text: "Select Option",
                value: "",
              },
              {
                text: "Possible Exposure",
                value: "Possible Exposure",
              },
              {
                text: "Confirmed Exposure",
                value: "Confirmed Exposure",
              },
              {
                text: "Asymptomatic",
                value: "Asymptomatic",
              },
            ]}
          />
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiHorizontalRule />

      <Symptoms />

      <EuiHorizontalRule />

      <Questions />
    </Validation>
  );
}

export default Medical;
