import axios from "axios";

const _axios = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

_axios.interceptors.request.use(
  async function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

_axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export const setDefaultHeader = (key: string, value: any) => {
  _axios.defaults.headers.common[key] = value;
};

export default _axios;
