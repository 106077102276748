import { useState, useCallback, useEffect } from "react";

export const useStepValue = (defaultSteps) => {
  const [index, setIndex] = useState(0);
  const [steps, setSteps] = useState(defaultSteps);
  const [currentStep, setCurrentStep] = useState({});

  const nextStep = useCallback(() => {
    setIndex((step) => step + 1);
  }, []);

  const backStep = useCallback(() => {
    setIndex((step) => step - 1);
  }, []);

  useEffect(() => {
    setCurrentStep(steps?.[index]);
  }, [index]);

  return {
    currentIndex: index,
    currentStep,
    nextStep,
    backStep,
  };
};

export default useStepValue;
