import { EuiFormRow } from "@elastic/eui";
import ScheduleTimePicker from "./ScheduleTimePicker";

const ScheduleTimePickerField = ({
  state,
  onChange = (value: any) => {},
  locationId,
  clearValue,
}) => {
  return (
    <>
      <EuiFormRow
        fullWidth
        label={state.title}
        helpText={state?.error?.message || ""}>
        <>
          <ScheduleTimePicker
            onChange={onChange}
            clearValue={clearValue}
            locationId={locationId}></ScheduleTimePicker>
        </>
      </EuiFormRow>
    </>
  );
};

export default ScheduleTimePickerField;
