import { useContext } from "react";

import { EuiFlexGroup, EuiFlexItem, EuiText } from "@elastic/eui";

import { CheckboxField } from "modules/commons/components";

import FormContext from "modules/sign-up/context/FormContext";
import Validation from "modules/sign-up/components/Validation";

function Medical({ companyName = "" }) {
  const form = useContext(FormContext);

  return (
    <Validation fields={["consent"]}>
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiText grow={false}>
            <h1>Informed Consent for administration of vaccines</h1>
            <p>
              I have read the adverse reactions associated with the
              administration of vaccines. I have also had an opportunity to ask
              questions about these immunizations. I believe the benefits
              outweigh the risks and I voluntarily assume full responsibility
              for any reactions that may result from either my receipt of the
              immunization(s) or the receipt of the immunization(s) by the
              person named above for whom I am the legal guardian (‘Ward’). My
              medical record may be shared with my physician or other healthcare
              provider and the medical record of my Ward may be shared with
              his/her physician or other healthcare provider. I am requesting
              that the immunization(s) be given to me or my Ward. I, for myself
              and on behalf of my Ward, and each of our respective heirs,
              executors, personal representatives and assigns, hereby release
              (name of Organization) and its affiliates, subsidiaries,
              divisions, directors, contractors, agents and employees
              (collectively “Released Parties”), from any and all claims arising
              out of, in connection with or in any way related to my receipt and
              the receipt by my Ward of this or these immunization(s).
              Neither(name of Organization) nor any of the Released Parties
              shall, at any time or to any extent whatsoever, be liable,
              responsible or any way accountable for any loss, injury, death or
              damage suffered or sustained by any person at any time in
              connection with or as a result of this vaccine program or the
              administration of the vaccines described above.
            </p>
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiFlexGroup>
        <EuiFlexItem>
          <CheckboxField {...form.consent} />
        </EuiFlexItem>
      </EuiFlexGroup>
    </Validation>
  );
}

export default Medical;
