import { EuiCallOut } from "@elastic/eui";

const Error = () => {
  return (
    <EuiCallOut
      title="Sorry, there was an error"
      color="danger"
      iconType="alert"
    />
  );
};

export default Error;
