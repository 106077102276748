import axios from "axios";

const getToken = ({ key, softwareName, softwareVersion }, tokenType, data) => {
  const options = {
    method: "POST",
    url: "https://cdn.cardknox.com/api/ifields/gettoken",
    headers: { "content-type": "application/json" },
    data: {
      xKey: key,
      xVersion: "2.6.2006.0102",
      xReferrer: "",
      xSoftwareName: softwareName,
      xSoftwareVersion: softwareVersion,
      xTokenType: tokenType,
      xTokenVersion: 1,
      xData: data,
    },
  };

  return axios.request(options);
};

export default { getToken };
