import { EuiFlexGroup, EuiFlexItem, EuiText } from "@elastic/eui";

function MonkeypoxDisclaimer() {
  return (
    <EuiFlexGroup>
      <EuiFlexItem>
        <EuiText grow={false}>
          <h2>
            This page is for making appointments for monkeypox vaccination.
          </h2>
          <div>
            <div>
              <div>
                <p>
                  <span lang="en-US">
                    <span>People who meet&nbsp;</span>
                  </span>
                  <span lang="en-US">
                    <span>all</span>
                  </span>
                  <span lang="en-US">
                    <span>&nbsp;of</span>
                    <span>&nbsp;the following criteria can be vaccinated:</span>
                  </span>
                  <span>&nbsp;</span>
                </p>
              </div>
              <ul>
                <li>
                  <p>
                    <span lang="en-US">
                      <span>
                        Are a gay, bisexual, or other man who has sex with men
                        and/or are transgender, gender non-conforming, or gender
                        non-
                      </span>
                      <span>binary;</span>
                      <span> </span>
                    </span>
                    <span>&nbsp;</span>
                  </p>
                </li>
                <li>
                  <p>
                    <span lang="en-US">
                      <span>Are age 18 or older;&nbsp;</span>
                    </span>
                    <span lang="en-US">
                      <span>and</span>
                    </span>
                    <span>&nbsp;</span>
                  </p>
                </li>
              </ul>
            </div>
            <div>
              <ul>
                <li>
                  <p>
                    <span lang="en-US">
                      <span>
                        Have had multiple or anonymous sex partners in the last
                        14 days.
                      </span>
                    </span>
                    <span>&nbsp;</span>
                  </p>
                </li>
              </ul>
              <div>
                <p>
                  <span lang="en-US">
                    <b>
                      If you do not meet these criteria, please do not make an
                      appointment.
                    </b>
                  </span>
                  <span lang="en-US">
                    <span>&nbsp;You&nbsp;</span>
                    <span>are not eligible to&nbsp;</span>
                    <span>be vaccinated at this time.&nbsp;</span>
                  </span>
                  <span lang="en-US">
                    <span>Please continue to check&nbsp;</span>
                  </span>
                  <a href="https://nyc.gov/health/monkeypox">
                    nyc.gov/health/monkeypox
                  </a>
                  <span lang="en-US">
                    <span>&nbsp;for&nbsp;</span>
                    <span>updates.&nbsp;</span>
                  </span>
                  <span>&nbsp;</span>
                </p>
              </div>
              <div>
                <p>
                  <span lang="en-US">
                    <b>
                      Appointments are required and can only be made through
                      this webpage.
                    </b>
                  </span>
                  <span lang="en-US">
                    <span>
                      &nbsp;Please do not make multiple appointments.&nbsp;
                    </span>
                  </span>
                  <span>&nbsp;</span>
                </p>
              </div>
            </div>
            <div>
              <p>
                <span lang="en-US">
                  <span>The&nbsp;</span>
                  <span>MedRite</span>
                  <span>
                    &nbsp;call center is not able to make appointments at this
                    time.
                  </span>
                  <span>
                    If you need to cancel or reschedule your existing
                    appointment, you can do so by calling 929-564-8344.
                  </span>
                </span>
              </p>
            </div>
          </div>
        </EuiText>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
}

export default MonkeypoxDisclaimer;
