import { useContext } from "react";

import {
  EuiButtonGroup,
  EuiFlexGroup,
  EuiFlexItem,
  EuiTitle,
} from "@elastic/eui";

import {
  TextField,
  Show,
  FileField,
  CheckboxField,
} from "modules/commons/components";

import FormContext from "modules/sign-up/context/FormContext";
import PaymentForm from "../Payment/PaymentForm";

function Insurance({
  loading = false,
  requireInsurance = false,
  allowCreditCard = false,
}) {
  const context = useContext(FormContext);

  return (
    <>
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiTitle size="s">
            <h2>Payment</h2>
          </EuiTitle>
        </EuiFlexItem>
      </EuiFlexGroup>
      <Show when={!requireInsurance}>
        <EuiFlexGroup>
          <EuiFlexItem>
            <CheckboxField {...context.insurance} />
          </EuiFlexItem>
        </EuiFlexGroup>
      </Show>
      <Show when={!context.insurance.state.value}>
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiTitle size="s">
              <h2>Insurance</h2>
            </EuiTitle>
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiFlexGroup>
          <EuiFlexItem>
            <TextField {...context.insuranceMemberName} />
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiFlexGroup>
          <EuiFlexItem>
            <TextField {...context.insuranceProvider} />
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiFlexGroup>
          <EuiFlexItem>
            <TextField {...context.insuranceMemberNumber} />
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiFlexGroup>
          <EuiFlexItem>
            <TextField {...context.insuranceGroupNumber} />
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiFlexGroup>
          <EuiFlexItem>
            <FileField
              {...context.insuranceCardPictureFileName}
              loading={loading}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </Show>

      <Show when={allowCreditCard && context.insurance.state.value}>
        <PaymentForm />
      </Show>
    </>
  );
}

export default Insurance;
