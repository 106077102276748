import { EuiHorizontalRule } from "@elastic/eui";

import Contact from "./Contact";
import Patient from "./Patient";
import Address from "./Address";

import Validation from "modules/sign-up/components/Validation";

function General(options) {
  return (
    <Validation
      fields={[
        "firstName",
        "lastName",
        "dob",
        "gender",
        "phone",
        "email",
        "street",
        "apt",
        "city",
        "state",
        "zip",
      ]}
      isFieldOptional={(key, form) => {
        if (options.hide && options?.hide[key]) return true;
      }}>
      <Contact />

      <EuiHorizontalRule />

      <Patient {...options} />

      <EuiHorizontalRule />

      <Address />
    </Validation>
  );
}

export default General;
