import { useContext, useEffect, useState } from "react";

import FormContext from "modules/sign-up/context/FormContext";
import moment from "moment";
import { EuiButton } from "@elastic/eui";

const Reservation = () => {
  const context = useContext(FormContext);
  const [time, setTime] = useState(Date.now());

  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const reservationExpirationDate = context.reservation.expirationDate?.value;

  if (!reservationExpirationDate) return null;

  var durationMs = moment
    .duration(moment(context.reservation.expirationDate.value).diff(moment()))
    .as("milliseconds");

  return (
    <>
      <div style={{ color: "red" }}>
        {durationMs > 0 && (
          <b>Reservation expires in {moment.utc(durationMs).format("mm:ss")}</b>
        )}
        {durationMs <= 0 && (
          <b>
            Sorry, your Reservation has expired. Please refresh to schedule a
            new appointment.
          </b>
        )}
      </div>
    </>
  );
};

export default Reservation;
