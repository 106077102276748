import { useContext } from "react";

import { EuiFlexGroup, EuiFlexItem, EuiTitle } from "@elastic/eui";

import {
  TextField,
  DateField,
  SelectField,
  Show,
} from "modules/commons/components";

import options from "modules/commons/options";

import FormContext from "modules/sign-up/context/FormContext";

function Patient({ hide }) {
  const context = useContext(FormContext);
  return (
    <>
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiTitle size="s">
            <h2>Patient</h2>
          </EuiTitle>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiFlexGroup>
        <EuiFlexItem>
          <TextField {...context.firstName} />
        </EuiFlexItem>
        <EuiFlexItem>
          <TextField {...context.lastName} />
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiFlexGroup>
        <EuiFlexItem>
          <DateField {...context.dob} />
        </EuiFlexItem>
        <Show when={!hide?.gender}>
          <EuiFlexItem>
            <SelectField {...context.gender} options={options.genders} />
          </EuiFlexItem>
        </Show>
      </EuiFlexGroup>
    </>
  );
}

export default Patient;
