import { useContext } from "react";

import { EuiFlexGroup, EuiFlexItem, EuiSpacer } from "@elastic/eui";

import { CheckboxField, Show, DateField } from "modules/commons/components";

import FormContext from "modules/sign-up/context/FormContext";

const Symptoms = () => {
  const form = useContext(FormContext);

  return (
    <>
      <EuiFlexGroup>
        <EuiFlexItem>
          <CheckboxField {...form.symptom} />
        </EuiFlexItem>
      </EuiFlexGroup>

      <Show when={form.symptom.state.value}>
        <EuiFlexGroup>
          <EuiFlexItem>
            <DateField {...form.symptomDate} />
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiFlexGroup>
          <EuiFlexItem>
            <p>Please check the symptoms you are currently experiencing:</p>
            <EuiSpacer />
            <CheckboxField {...form.symptomCough} />
            <CheckboxField {...form.symptomBreath} />
            <CheckboxField {...form.symptomMuscle} />
            <CheckboxField {...form.symptomDiarrhea} />
            <CheckboxField {...form.symptomFever} />
            <CheckboxField {...form.symptomThroat} />
            <CheckboxField {...form.symptomChills} />
            <CheckboxField {...form.symptomVomiting} />
            <CheckboxField {...form.symptomNose} />
            <CheckboxField {...form.symptomHeadache} />
            <CheckboxField {...form.symptomAbdominal} />
          </EuiFlexItem>
        </EuiFlexGroup>
      </Show>
    </>
  );
};

export default Symptoms;
