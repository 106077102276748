import React from "react";

import { EuiFormRow, EuiDatePickerRange } from "@elastic/eui";

import { DateField } from "./DateField";

function DateRangeField({ title = "", startDate = {}, endDate = {} }) {
  return (
    <EuiFormRow fullWidth label={title}>
      <EuiDatePickerRange
        startDateControl={<DateField {...startDate} />}
        endDateControl={<DateField {...endDate} />}
      />
    </EuiFormRow>
  );
}

export default DateRangeField;
