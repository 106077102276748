import React, { useEffect, useMemo, useState } from "react";

import { EuiComboBox, EuiFormRow } from "@elastic/eui";

//FIXME: Replace this component for the ComboBoxField

function locationToOption(location) {
  return {
    id: location.id,
    label: `${location.schoolName} (${location.orgTestingCode})`,
  };
}

function LocationSelect({
  selected = {},
  options = [],
  isLoading = false,
  onChange = () => {},
}) {
  const [selectedLocation, setSelectedLocation] = useState([]);

  const _options = useMemo(() => {
    return options.map(locationToOption);
  }, [options?.length]);

  useEffect(() => {
    if (!selected?.id) {
      return;
    }
    const _selected = options.find((option) => option?.id === selected?.id);
    const selectedOptions = _selected ? [_selected] : [];
    setSelectedLocation(selectedOptions);
  }, [selected?.id]);

  const _onChange = (values = []) => {
    if (values.length > 0) {
      const [value] = values;
      const location = options.find((location) => location.id === value.id);
      onChange(location);
    } else {
      onChange({});
    }

    setSelectedLocation(values);
  };

  return (
    <EuiFormRow fullWidth label="Location">
      <EuiComboBox
        fullWidth
        placeholder="Select a location"
        singleSelection={{ asPlainText: true }}
        options={_options}
        selectedOptions={selectedLocation}
        onChange={_onChange}
        isLoading={isLoading}
        isDisabled={isLoading}
      />
    </EuiFormRow>
  );
}

export default LocationSelect;
