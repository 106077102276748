import yesno from "./yesno";
import races from "./races";
import genders from "./genders";
import usaStates from "./usaStates";
import testTypes from "./testTypes";

import phoneTypes from "./phoneTypes";
import insurances from "./insurances";
import testResults from "./testResults";
import ethnicities from "./ethnicities";
import occupations from "./occupations";
import vaccineSites from "./vaccineSites";
import relationships from "./relationships";
import insuranceRelationship from "./insuranceRelationship";

export default {
  yesno,
  races,
  genders,
  usaStates,
  testTypes,
  phoneTypes,
  insurances,
  testResults,
  ethnicities,
  occupations,
  vaccineSites,
  relationships,
  insuranceRelationship,
};
