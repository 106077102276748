import React from "react";

import { EuiFormRow, EuiFieldText } from "@elastic/eui";

function TextField({ state = {}, onChange = () => {} }) {
  return (
    <EuiFormRow
      fullWidth
      label={state.title}
      helpText={state?.error?.message || ""}
    >
      <EuiFieldText
        fullWidth
        value={state.value}
        placeholder={state.placeholder}
        disabled={state.disabled}
        isInvalid={!!state.error}
        autoComplete={state.autocomplete}
        onChange={(e) => onChange(e.target.value)}
      />
    </EuiFormRow>
  );
}

export default TextField;
