import { EuiHorizontalRule } from "@elastic/eui";

import Card from "./Card";
import Insurance from "./Insurance";
import Validation from "../Validation";

const idFields = {
  idNumber: true,
  idState: true,
  idPictureFileName: true,
};

const insuranceFields = {
  insuranceProvider: true,
  insuranceMemberNumber: true,
  insuranceMemberName: true,
  insuranceCardPictureFileName: true,
};

const paymentFields = {
  creditCardNumber: true,
  creditCardExpiration: true,
  creditCardCvv: true,
  creditCardName: true,
  creditCardZipCode: true,
};

function Credentials({ requireInsurance = false, allowCreditCard = false }) {
  return (
    <Validation
      fields={[
        "idNumber",
        "idState",
        "idPictureFileName",

        "insuranceProvider",
        "insuranceMemberNumber",
        "insuranceMemberName",
        "insuranceCardPictureFileName",

        "creditCardNumber",
        "creditCardExpiration",
        "creditCardCvv",
        "creditCardName",
        "creditCardZipCode",
      ]}
      isFieldOptional={(key, form) => {
        if (idFields[key]) {
          const hasIdCard = form?.["idCard"]?.state?.value;
          return !!hasIdCard;
        } else {
          const doesntHaveInsurance = form?.["insurance"]?.state?.value;
          if (insuranceFields[key]) {
            return doesntHaveInsurance;
          } else if (paymentFields[key]) {
            if (!allowCreditCard) return true;

            return !doesntHaveInsurance;
          }
        }
      }}>
      <Card />

      <EuiHorizontalRule />

      <Insurance
        requireInsurance={requireInsurance}
        allowCreditCard={allowCreditCard}
      />
    </Validation>
  );
}

export default Credentials;
