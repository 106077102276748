import { useReducer } from "react";

interface State {
  value: any;
  title?: string;
  placeholder: string;
  disabled: boolean;
  error?: null;
}

interface UseFormFieldProps extends State {
  validation: (value: any) => {};
  formatter: (value: any) => {};
}

function useFormField({ validation, formatter, ...props }: UseFormFieldProps) {
  const [state, dispatch]: [State, any] = useReducer(reducer, {
    title: "",
    placeholder: "",
    value: "",
    disabled: false,
    error: false,
    ...props,
  });

  const onChange = (value) => {
    dispatch({
      type: "onChange",
      payload: formatter ? formatter(value) : value,
    });
  };

  const onError = (error) => {
    dispatch({ type: "onError", payload: error });
  };

  const setAsValid = () => {
    dispatch({ type: "setAsValid" });
  };

  const disable = (payload = true) => {
    dispatch({ type: "disable", payload });
  };

  const reset = () => {
    dispatch({ type: "reset", payload: props });
  };

  const validate = () => {
    try {
      validation?.(state.value || "");
      setAsValid();
    } catch (error) {
      onError(error);
      return error;
    }
  };

  return {
    state,
    value: state.value,
    onChange,
    onError,
    validate,
    setAsValid,
    disable,
    reset,
  };
}

function reducer(state: State, action) {
  switch (action.type) {
    case "onChange":
      return {
        ...state,
        value: action.payload,
      };
    case "onError":
      return {
        ...state,
        error: action.payload,
      };
    case "setAsValid":
      return {
        ...state,
        error: null,
      };
    case "disable":
      return {
        ...state,
        disabled: action.payload,
      };
    case "reset":
      return { ...action.payload };
    default:
      throw new Error();
  }
}

export default useFormField;

export type FormField = ReturnType<typeof useFormField>;
