import { EuiCallOut, EuiFlexGroup, EuiFlexItem } from "@elastic/eui";

const NotFound = () => (
  <EuiFlexGroup>
    <EuiFlexItem>
      <EuiCallOut title="Error: 404" iconType="lensApp">
        <p>Page not found</p>
      </EuiCallOut>
    </EuiFlexItem>
  </EuiFlexGroup>
);

export default NotFound;
