import { useContext } from "react";

import { EuiFlexGroup, EuiFlexItem, EuiText } from "@elastic/eui";

import FormContext from "modules/sign-up/context/FormContext";
import Validation from "modules/sign-up/components/Validation";
import moment from "moment";

function Medical({ companyName = "" }) {
  const context = useContext(FormContext);

  return (
    <Validation>
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiText grow={false}>
            <h1>Complete Registration</h1>
            {context.appointmentTime.value != null && (
              <>
                Appointment date:{" "}
                <b>
                  {moment(context.appointmentTime.value).format(
                    "dddd, MMMM Do YYYY, h:mm:ss a"
                  )}
                </b>
              </>
            )}
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
    </Validation>
  );
}

export default Medical;
