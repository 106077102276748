import { useContext } from "react";

import { EuiFlexGroup, EuiFlexItem, EuiTitle, EuiText } from "@elastic/eui";

import { CheckboxField } from "modules/commons/components";

import FormContext from "modules/sign-up/context/FormContext";
import Validation from "modules/sign-up/components/Validation";
import RawHTMLGroup from "../Shared/RawHTMLGroup";

function RawHtmlConsent({ companyName = "", consentHtml }) {
  const form = useContext(FormContext);
  console.log(consentHtml);
  return (
    <Validation fields={["consent"]}>
      <RawHTMLGroup htmlContent={consentHtml} />
      <EuiFlexGroup>
        <EuiFlexItem>
          <CheckboxField {...form.consent} />
        </EuiFlexItem>
      </EuiFlexGroup>
    </Validation>
  );
}

export default RawHtmlConsent;
