import { useCallback, useState } from "react";
import _get from "lodash/get";
import axios from "utils/axios";

type Status = "loading" | "ready" | "error";

const useLocations = () => {
  const [locations, setLocations] = useState<any>();
  const [settings, setSettings] = useState<any>();
  const [status, setStatus] = useState<Status>("loading");

  const fetchLocations = useCallback(async () => {
    try {
      const response = await axios.get(`/LocationInfo`);

      const data = _get(response, "data", "");

      setLocations(data?.locations);
      setSettings(data?.settings);
      setStatus("ready");
    } catch (error) {
      setStatus("error");
    }
  }, []);

  return { fetchLocations, locations, status, settings };
};

export default useLocations;
