import { useContext, useState } from "react";

import useQuery from "modules/commons/hooks/useQuery";
import Validation from "modules/sign-up/components/Validation";
import FormContext from "modules/sign-up/context/FormContext";
import ScheduleTimePickerField from "./ScheduleTimePickerField";
import { useFetch } from "modules/commons/hooks";

const Schedule = () => {
  const query = useQuery();
  const context = useContext(FormContext);
  const [clearValue, setClearValue] = useState(null);
  const locationId = query.get("id");

  const reservations = useFetch({
    url: `/AppointmentScheduler/location/${locationId}/reserve`,
    method: "post",
  });

  const onNext = async () => {
    const response = await reservations.fetch(
      `?date=${context.appointmentTime.value}`
    );

    if (!response.data.reserved) {
      context.reservation.payload = null;

      alert(
        "The selected time is no longer available, please select another time slot"
      );

      context.appointmentTime.onChange(null);
      context.appointmentReservationId.onChange(null);

      setClearValue({});
      return false;
    }

    context.reservation.payload = response.data;

    context.reservation.expirationDate.onChange(
      context.reservation.payload.expirationDate
    );

    context.appointmentReservationId.onChange(context.reservation.payload.id);

    return context.reservation.payload?.id;
  };

  return (
    <Validation
      fields={["appointmentTime"]}
      nextButtonText="Reserve and Continue"
      onNext={onNext}
      canContinue={context.appointmentTime.value}>
      <ScheduleTimePickerField
        {...context.appointmentTime}
        locationId={locationId}
        clearValue={clearValue}></ScheduleTimePickerField>
    </Validation>
  );
};

export default Schedule;
