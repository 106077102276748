import { useState, useCallback } from "react";
import axios from "utils/axios";

function useFetch({ url = "", method = "get" } = {}) {
  const [isLoading, setIsLoading] = useState(false);
  const [payload, setPayload] = useState(null);
  const [error, setError] = useState(null);

  const fetch = useCallback(
    async (query = null) => {
      try {
        setIsLoading(true);

        const _url = [url, query].filter((value) => value).join("");

        const response = await axios({
          method: method,
          url: _url,
        });

        setPayload(response?.data);

        return response;
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    },
    [url]
  );

  return {
    fetch,
    isLoading,
    error,
    payload,
  };
}

export default useFetch;
