function compress(file: File, maxWidth: number = 1024): Promise<string> {
  const url = URL.createObjectURL(file);

  const image = new Image();
  image.src = url;

  return new Promise((resolve, reject) => {
    image.onload = function (e: Event) {
      const image = e.target as HTMLImageElement;

      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      canvas.width = maxWidth;
      canvas.height = image.height * (maxWidth / image.width);

      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

      const url = ctx.canvas.toDataURL("image/jpeg");

      resolve(url);
    };

    image.onerror = (error) => reject(error);
  });
}

export default compress;
