import { useRef } from "react";
import { EuiFormRow, EuiCheckbox } from "@elastic/eui";

export const useGeneratedHtmlId = (prefix) => {
  const idRef = useRef(null);

  if (!idRef.current) {
    idRef.current = `${prefix}-${Date.now().toString(36)}`;
  }

  return idRef.current;
};

function CheckboxField({ state = {}, onChange = () => {} }) {
  const checkboxId = useGeneratedHtmlId({
    prefix: state.title,
  });

  return (
    <EuiFormRow fullWidth label=" " helpText={state?.error?.message || ""}>
      <EuiCheckbox
        id={checkboxId}
        label={state.title}
        checked={!!state.value}
        onChange={(e) => onChange(e.target.checked)}
        compressed
      />
    </EuiFormRow>
  );
}

export default CheckboxField;
