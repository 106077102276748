import { useEffect } from "react";
import _get from "lodash/get";
import { useParams, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";

import {
  EuiFlexItem,
  EuiFlexGroup,
  EuiTitle,
  EuiHorizontalRule,
  EuiLoadingContent,
  EuiCard,
} from "@elastic/eui";

import { setDefaultHeader } from "utils/axios";

import { Logo, Show } from "modules/commons/components";
import { Error } from "modules/sign-up/components";

import useLocations from "modules/commons/hooks/useLocations";
import useAssets from "modules/commons/hooks/useAssets";

import "./index.css";
import RawHTMLGroup from "modules/sign-up/components/Shared/RawHTMLGroup";

const LocationsView = ({ logo, status, locations, settings }) => {
  const history = useHistory();

  const cardNodes = locations?.map(function (item, index) {
    return (
      <EuiFlexItem key={index}>
        <EuiCard
          title={`${item.locationName}`}
          isDisabled={false}
          description={`${item.street1} ${item.city}, ${item.state} ${item.zip}`}
          onClick={() => {
            history.push(`register?id=${item.id}`);
          }}
        />
      </EuiFlexItem>
    );
  });

  if (locations?.length == 0) {
    return (
      <div className="Locations">
        <Error />
      </div>
    );
  }

  return (
    <div className="Locations">
      <EuiFlexGroup alignItems="center">
        <EuiFlexItem grow={false}>
          <Logo src={logo} />
        </EuiFlexItem>

        <EuiFlexItem>
          <EuiTitle>
            <h1>Choose a Location</h1>
          </EuiTitle>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiHorizontalRule />

      <Show when={status === "error"}>
        <Error />
      </Show>

      <Show when={status === "loading"}>
        <EuiLoadingContent lines={3} />
      </Show>

      <Show when={status === "ready"}>
        {settings?.disclaimer && (
          <>
            <RawHTMLGroup htmlContent={settings.disclaimer?.html} />
            <EuiHorizontalRule />
          </>
        )}

        <div>Please select a location</div>
        <EuiFlexGroup gutterSize="l">{cardNodes}</EuiFlexGroup>
      </Show>
    </div>
  );
};

const Locations = () => {
  const { tenant } = useParams();
  const { fetchLocations, status, locations, settings } = useLocations();
  const logo = useAssets();

  useEffect(() => {
    const fetchData = async () => {
      setDefaultHeader("tenant", tenant);
      await fetchLocations();
    };
    fetchData();
  }, [tenant]);

  return (
    <LocationsView
      logo={logo}
      status={status}
      locations={locations}
      settings={settings}
    />
  );
};

const _Locations = () => {
  return (
    <>
      <Helmet>
        <title>Locations</title>
      </Helmet>
      <Locations />
    </>
  );
};

export default _Locations;
