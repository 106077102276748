import { useEffect } from "react";
import { useParams } from "react-router-dom";

const url = "https://elitelabs.s3.us-east-1.amazonaws.com/public";

function getFavicon(): HTMLLinkElement {
  return document.getElementById("favicon") as any;
}

const useAssets = () => {
  const { tenant } = useParams();

  useEffect(() => {
    const favicon = getFavicon();
    favicon.href = `${url}/${tenant}/favicon.png`;
  }, [tenant]);

  return `${url}/${tenant}/logo.png`;
};

export default useAssets;
