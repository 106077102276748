import { FormField } from "modules/commons/hooks/useFormField";

const fileParser = async (
  form: Record<string, FormField>,
  uploadFile: (file: File) => any
) => {
  const result: Record<string, string> = {};

  for (const [key, state] of Object.entries(form)) {
    const filedState = state as any;

    switch (key) {
      case "idPictureFileName":
        result[key] = await uploadFile(filedState.value);
        break;
      case "insuranceCardPictureFileName":
        result[key] = await uploadFile(filedState.value);
        break;
      default:
        break;
    }
  }

  return result;
};

export default fileParser;
