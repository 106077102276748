import { useContext } from "react";
import _get from "lodash/get";
import { General, Medical, Consent, Complete, Credentials } from "./components";
import StepContext from "./context/StepContext";
import { Show } from "modules/commons/components";
import Schedule from "./components/Schedule";
import CompleteAppointment from "./components/Complete/CompleteAppointment";
import MonkeypoxVaccineInfo from "./components/IntroInfo/MonkeypoxVaccineInfo";
import Reservation from "./components/Reservation";

export function SignUpSteps({ locationType }) {
  const { currentStep } = useContext(StepContext);

  const companyName = _get(locationType, "companyName", "");

  return (
    <>
      <Show when={!currentStep.name?.endsWith("complete")}>
        <Reservation />
      </Show>

      <Show when={currentStep.name === "monkeypox-vaccine-intro-info"}>
        <MonkeypoxVaccineInfo {...currentStep.options} />
      </Show>

      <Show when={currentStep.name === "schedule"}>
        <Schedule {...currentStep.options} />
      </Show>

      <Show when={currentStep.name === "general"}>
        <General {...currentStep.options} />
      </Show>

      <Show when={currentStep.name === "credentials"}>
        <Credentials {...currentStep.options} />
      </Show>

      <Show when={currentStep.name === "medical"}>
        <Medical {...currentStep.options} />
      </Show>

      <Show when={currentStep.name === "covid-consent"}>
        <Consent.Covid companyName={companyName} {...currentStep.options} />
      </Show>

      <Show when={currentStep.name === "raw-consent"}>
        <Consent.Raw
          companyName={companyName}
          consentHtml={locationType.settings?.consent?.html}
          {...currentStep.options}
        />
      </Show>

      <Show when={currentStep.name === "monkeypox-vaccine-consent"}>
        <Consent.MonkeypoxVaccine
          companyName={companyName}
          {...currentStep.options}
        />
      </Show>

      <Show when={currentStep.name === "empty-consent"}>
        <Consent.Empty companyName={companyName} {...currentStep.options} />
      </Show>

      <Show when={currentStep.name === "complete"}>
        <Complete {...currentStep.options} />
      </Show>

      <Show when={currentStep.name === "appointment-complete"}>
        <CompleteAppointment {...currentStep.options} location={locationType} />
      </Show>
    </>
  );
}
