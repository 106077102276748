import { useContext } from "react";

import { EuiFlexGroup, EuiFlexItem, EuiTitle } from "@elastic/eui";

import { CheckboxField } from "modules/commons/components";

import FormContext from "modules/sign-up/context/FormContext";

const Symptoms = () => {
  const form = useContext(FormContext);

  return (
    <>
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiTitle size="s">
            <h2>Medical Questions</h2>
          </EuiTitle>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiFlexGroup>
        <EuiFlexItem>
          <CheckboxField {...form.medicalQuestion1} />
          <CheckboxField {...form.medicalQuestion2} />
          <CheckboxField {...form.medicalQuestion3} />
          <CheckboxField {...form.medicalQuestion4} />
          <CheckboxField {...form.medicalQuestion5} />
          <CheckboxField {...form.medicalQuestion6} />
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  );
};

export default Symptoms;
