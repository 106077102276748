import React, { useContext } from "react";

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiTitle,
} from "@elastic/eui";

import {
  TextField,
  SelectField,
  Show,
  FileField,
  CheckboxField,
} from "modules/commons/components";

import options from "modules/commons/options";

import FormContext from "modules/sign-up/context/FormContext";

function Card({ loading = false }) {
  const context = useContext(FormContext);

  return (
    <>
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiTitle size="s">
            <h2>Id Card</h2>
          </EuiTitle>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiFlexGroup>
        <EuiFlexItem>
          <CheckboxField {...context.idCard} />
        </EuiFlexItem>
      </EuiFlexGroup>

      <Show when={!context.idCard.state.value}>
        <EuiFlexGroup>
          <EuiFlexItem>
            <TextField {...context.idNumber} />
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiFlexGroup>
          <EuiFlexItem>
            <SelectField {...context.idState} options={options.usaStates} />
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiFlexGroup>
          <EuiFlexItem>
            <FileField {...context.idPictureFileName} loading={loading} />
          </EuiFlexItem>
        </EuiFlexGroup>
      </Show>
    </>
  );
}

export default Card;
