import { useEffect, useState } from "react";
import _get from "lodash/get";
import { useParams, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";

import {
  EuiFlexItem,
  EuiFlexGroup,
  EuiTitle,
  EuiHorizontalRule,
  EuiLoadingContent,
} from "@elastic/eui";

import { Error } from "./components";

import { FormProvider } from "./context/FormContext";
import { StepProvider } from "./context/StepContext";

import { setDefaultHeader } from "utils/axios";

import { Logo, Show } from "modules/commons/components";

import {
  covidSteps,
  covidbasicSteps,
  level1Steps,
  level1enforceinsuranceSteps,
  covidinsuranceorcreditcardSteps,
  level1insuranceorcreditcardSteps,
  monkeypoxvaccineSteps,
} from "./hooks/StepLists";
import useLocation from "modules/commons/hooks/useLocation";
import useAssets from "modules/commons/hooks/useAssets";
import { SignUpSteps } from "./SignUpSteps";

import "./index.css";
import "react-credit-cards/es/styles-compiled.css";

const stepsMap = {
  covid: covidSteps,
  covidbasic: covidbasicSteps,
  level1: level1Steps,
  level1enforceinsurance: level1enforceinsuranceSteps,
  covidinsuranceorcreditcard: covidinsuranceorcreditcardSteps,
  level1insuranceorcreditcard: level1insuranceorcreditcardSteps,
  monkeypoxvaccine: monkeypoxvaccineSteps,
};

const ConfigSelector = (type: string) => {
  return stepsMap[type];
};

const SignUpView = ({ logo, status, steps, locationType }) => {
  return (
    <div className="SignUp">
      <EuiFlexGroup alignItems="center">
        <EuiFlexItem grow={false}>
          <Logo src={logo} />
        </EuiFlexItem>

        <EuiFlexItem>
          <EuiTitle>
            <h1>Registration - {locationType?.companyName}</h1>
          </EuiTitle>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiHorizontalRule />

      <Show when={status === "error"}>
        <Error />
      </Show>

      <Show when={status === "loading"}>
        <EuiLoadingContent lines={3} />
      </Show>

      <Show when={status === "ready" && steps.length > 0}>
        <StepProvider steps={steps}>
          <SignUpSteps locationType={locationType} />
        </StepProvider>
      </Show>
    </div>
  );
};

const SignUp = () => {
  const { tenant } = useParams();
  const history = useHistory();
  const [config, setConfig] = useState({ steps: [] });
  const { fetchLocation, status, locationType } = useLocation();
  const logo = useAssets();

  useEffect(() => {
    const fetchData = async () => {
      setDefaultHeader("tenant", tenant);
      await fetchLocation();
    };
    fetchData();
  }, [tenant]);

  useEffect(() => {
    if (!locationType) return;
    const type = _get(locationType, "questionnaireTemplateType", "");
    const selectedConfig = ConfigSelector(type);
    setConfig(selectedConfig);
  }, [locationType]);

  useEffect(() => {
    if (status == "error") {
      history.push(`locations`);
    }
  }, [status]);

  return (
    <FormProvider config={config}>
      <>
        <Helmet>
          <title>{`Registration - ${locationType?.companyName ?? ""}`}</title>
        </Helmet>
        <SignUpView
          logo={logo}
          status={status}
          steps={config.steps}
          locationType={locationType}
        />
      </>
    </FormProvider>
  );
};

const _SignUp = () => {
  return <SignUp />;
};

export default _SignUp;
