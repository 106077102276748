import { useContext } from "react";

import { EuiFlexGroup, EuiFlexItem, EuiTitle } from "@elastic/eui";

import { TextField } from "modules/commons/components";

import FormContext from "modules/sign-up/context/FormContext";

function Contact() {
  const context = useContext(FormContext);

  return (
    <>
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiTitle size="s">
            <h2>Contact</h2>
          </EuiTitle>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiFlexGroup>
        <EuiFlexItem>
          <TextField {...context.phone} />
        </EuiFlexItem>
        <EuiFlexItem>
          <TextField {...context.email} />
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  );
}

export default Contact;
