import { useCallback, useState } from "react";

import axios from "utils/axios";

import fileParser from "modules/sign-up/helpers/fileParser";
import fieldParser from "modules/sign-up/helpers/fieldParser";
import creditCardPayments from "modules/sign-up/helpers/creditCardPayments";
import useUploadFile from "modules/sign-up/hooks/useUploadFile";
import useQuery from "modules/commons/hooks/useQuery";

const useSubmit = (onSuccess?: (data: any) => void) => {
  const [isLoading, setLoading] = useState(false);

  const query = useQuery();
  const { upload } = useUploadFile();

  const submit = useCallback(async (form = {}) => {
    setLoading(true);

    try {
      const fields = fieldParser(form);
      const files = await fileParser(form, upload);
      const paymentFields = await creditCardPayments(form);

      const response = await axios.post("/PatientRegistration/register", {
        ...fields,
        ...files,
        ...paymentFields,
        locationId: query.get("id"),
        externalEmrNumber: query.get("refId"),
      });

      if (response.data.hasError) {
        let errorMessage = response.data.errorType;

        if (response.data.errorType == "NoReservation") {
          errorMessage =
            "The selected time is no longer available, please select another time slot.";
        }

        alert(errorMessage);
      } else {
        onSuccess?.(response.data);
      }
    } catch (error) {
      console.log("useSubmit", error);
      alert("Error while saving the registration.");
    } finally {
      setLoading(false);
    }
  }, []);

  return { isLoading, submit };
};

export default useSubmit;
