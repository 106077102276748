import React from "react";
import logo from "assets/logo.png";

interface LogoProps {
  src: string;
}

const Logo: React.FC<LogoProps> = ({ src }) => {
  return (
    <img
      alt="Logo"
      className="Logo"
      style={{ padding: 8, maxWidth: 200 }}
      src={src}
    />
  );
};

export default Logo;
