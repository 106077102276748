const options = [
  {
    value: "",
    text: "Select Option",
  },
  {
    value: "1",
    text: "Self",
  },
  {
    value: "2",
    text: "Spouse",
  },
  {
    value: "3",
    text: "Dependent",
  },
];

export default options;
