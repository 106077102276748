import ReactDOM from "react-dom";
import App from "modules/app";

import * as serviceWorker from "./serviceWorker";

import "./index.css";

import "@elastic/eui/dist/eui_theme_light.css";

ReactDOM.render(<App />, document.getElementById("root"));

serviceWorker.unregister();
