import { FormField } from "modules/commons/hooks/useFormField";

import cardknoxApi from "networking/cardknox-ifields";

const creditCardPayments = async (form: Record<string, FormField>) => {
  const result: Record<string, string> = {};

  if (!form["insurance"].value) {
    result["paymentType"] = "insurance";
    return result;
  }

  if (form["creditCardNumber"].value) {
    const options = {
      softwareName: "EliteLabs Registartion",
      softwareVersion: "1.0.0",
      key: "ifields_ritemediccarepcbe25085920cb454290fc7d",
    };
    
    const cardNumberResponse = await cardknoxApi.getToken(
      options,
      "card",
      form["creditCardNumber"].value
    );

    result["creditCardNumberToken"] =
      cardNumberResponse.data.xResultData.xToken;
    result["creditCardNumberLast4"] = form["creditCardNumber"].value.slice(-4);

    result["paymentType"] = "creditcard";

    const cvvResponse = await cardknoxApi.getToken(
      options,
      "cvv",
      form["creditCardCvv"].value
    );

    result["creditCardCvvToken"] = cvvResponse.data.xResultData.xToken;
  } else {
    result["paymentType"] = "nopayment";
  }

  return result;
};

export default creditCardPayments;
