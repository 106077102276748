import { useContext } from "react";
import { EuiCallOut } from "@elastic/eui";
import FormContext from "modules/sign-up/context/FormContext";
import moment from "moment";
import { QRCodeSVG } from "qrcode.react";

const CompleteAppointment = ({ doNotShowRequirments, location }) => {
  const context = useContext(FormContext);
  return (
    <>
      <p style={{ paddingBottom: "20px" }}>
        <b>
          Please print or screenshot the confirmation with the QR code; you will
          need to present it when checking in.
        </b>
      </p>
      <div>
        <EuiCallOut
          title="Great News, Appointment Confirmed"
          color="success"
          iconType="user">
          {doNotShowRequirments || (
            <>
              <div>
                <p>
                  Name:{" "}
                  <b>
                    {context.firstName.value} {context.lastName.value}
                  </b>
                </p>
                Thank you. Your appointment is scheduled for:
                <div>
                  <b>
                    {moment(context.appointmentTime.value).format(
                      "dddd, MMMM Do YYYY, h:mm:ss a"
                    )}
                  </b>
                  <div>
                    <div>Confirmation Code:</div>
                    <QRCodeSVG value={context.appointmentResponse.code.value} />
                  </div>
                  <p>
                    Appointment Address
                    <address>
                      <b>
                        {location.street1} {location.street2}
                        <br />
                        {location.city}, {location.state} {location.zip}
                      </b>
                    </address>
                  </p>
                </div>
                <p>
                  Please arrive 10 minutes early. Department of Health and Mental Hygiene concierge will be
                  outside to direct you on where to go.
                </p>
              </div>
            </>
          )}
        </EuiCallOut>
      </div>
    </>
  );
};

export default CompleteAppointment;
