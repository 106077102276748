import { useState, useContext } from "react";
import Cards from "react-credit-cards";
import { EuiFlexGroup, EuiFlexItem, EuiTitle } from "@elastic/eui";
import PaymentTextField from "./PaymentTextField";
import FormContext from "modules/sign-up/context/FormContext";
import { TextField } from "modules/commons/components";

function PaymentForm() {
  const context = useContext(FormContext);
  const [focused, setFocused] = useState();

  const handleInputFocus = ({ target }) => {
    setFocused(target.name);
  };

  return (
    <div id="PaymentForm">
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiTitle size="s">
            <h2>Credit Card</h2>
          </EuiTitle>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiFlexGroup>
        <EuiFlexItem>
          <h3>
            Note: Your card will only be charged when you take the test at the
            location.
          </h3>
        </EuiFlexItem>
      </EuiFlexGroup>
      <Cards
        cvc={context.creditCardCvv.value}
        expiry={context.creditCardExpiration.value}
        name={context.creditCardName.value}
        number={context.creditCardNumber.value}
        focused={focused}
      />
      <EuiFlexGroup>
        <EuiFlexItem>
          <PaymentTextField
            {...context.creditCardNumber}
            onFocus={handleInputFocus}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiFlexGroup>
        <EuiFlexItem>
          <PaymentTextField
            {...context.creditCardExpiration}
            onFocus={handleInputFocus}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiFlexGroup>
        <EuiFlexItem>
          <PaymentTextField
            {...context.creditCardCvv}
            onFocus={handleInputFocus}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiFlexGroup>
        <EuiFlexItem>
          <PaymentTextField
            {...context.creditCardName}
            onFocus={handleInputFocus}
          />
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiFlexGroup>
        <EuiFlexItem>
          <TextField {...context.creditCardZipCode} />
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  );
}

export default PaymentForm;
