import { EuiFormRow, EuiFieldText } from "@elastic/eui";

function PaymentTextField({
  state = {},
  onChange = () => {},
  onFocus = () => {},
}) {
  return (
    <EuiFormRow
      fullWidth
      label={state.title}
      helpText={state?.error?.message || ""}>
      <EuiFieldText
        fullWidth
        value={state.value}
        placeholder={state.placeholder}
        name={state.name}
        type={state.type}
        disabled={state.disabled}
        isInvalid={!!state.error}
        autoComplete={state.autocomplete}
        pattern={state.pattern}
        onChange={(e) => onChange(e.target.value)}
        onFocus={onFocus}
        required
      />
    </EuiFormRow>
  );
}

export default PaymentTextField;
